




























































































































































































































import { Component, Vue } from "vue-property-decorator";
import FaqSection from "./../components/sections/FaqSection.vue";

declare const jQuery: any;
declare const $: any;

@Component({
  components: {
    FaqSection,
  },
  metaInfo: {
    title: "About Us",
  },
})
export default class About extends Vue {
  public signInUrl = process.env.VUE_APP_SIGNIN_URL;
  public signUpUrl = process.env.VUE_APP_SIGNUP_URL;
  mounted() {
    console.log("This ran=====");
    if (jQuery(".popup-btn").length > 0) {
      console.log("Found popup");
      $("a.popup-btn").fancybox({
        transitionIn: "elastic",
        transitionOut: "elastic",
        speedIn: 600,
        speedOut: 200,
      });
    }
  }
}
